import React from 'react';
import { Container, Typography, Button, Card, CardContent, Box } from '@mui/material';
import MauticLogo from './mautic-logo.png'; // Replace with your Mautic logo path
import PhoneInputImage from './phone_format.png'; // Path to the uploaded phone input image

const App = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(-45deg, #0073e6, #d400ff, #00c853, #ff5722)',
        backgroundSize: '400% 400%',
        animation: 'gradient 30s ease infinite',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
        '@keyframes gradient': {
          '0%': { backgroundPosition: '0% 50%' },
          '50%': { backgroundPosition: '100% 50%' },
          '100%': { backgroundPosition: '0% 50%' },
        },
      }}
    >
      <Container maxWidth="sm" sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
          Supercharge Your Mautic Forms!
        </Typography>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Add international format phone number functionality to your Mautic forms with our latest plugin. 
          Seamlessly supports Mautic Version 5.
        </Typography>
        {/* Phone Input Image in Card */}
        <Card
          sx={{
            backgroundColor: '#fff',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
            borderRadius: 2,
            padding: 2,
            mb: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={PhoneInputImage}
            alt="International Phone Input"
            style={{
              width: '70%',
              height: 'auto',
              borderRadius: 4,
            }}
          />
        </Card>
        <Typography variant="body1" sx={{ mb: 3 }}>
          This plugin is perfect for businesses looking to streamline their customer contact forms. Enhance the user
          experience and ensure phone numbers are captured in a standardized format, no matter where your customers are
          located.
        </Typography>
        <Card
          sx={{
            backgroundColor: '#ffffffee',
            color: '#333',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
            mb: 4,
            padding: 2,
          }}
        >
          <CardContent>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
              Plugin Features:
            </Typography>
            <ul style={{ textAlign: 'left', paddingLeft: 20, color: '#333' }}>
              <li>Supports International Phone Number Format</li>
              <li>Works Seamlessly with Mautic Version 5</li>
              <li>Easy to Install and Use</li>
              <li>Lifetime Validity for $50</li>
            </ul>
          </CardContent>
        </Card>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#ff5722',
            color: '#fff',
            padding: '10px 20px',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#e64a19',
            },
          }}
          href="mailto:contact@xgeeksweb.com?subject=Mautic Plugin Query&body=Hi, I am interested in purchasing your Mautic plugin."
        >
          Purchase Now - $50
        </Button>
        <Typography variant="body2" sx={{ mt: 4 }}>
          Have questions or want to know more? Email us at{' '}
          <a href="mailto:contact@xgeeksweb.com" style={{ color: '#ffab40', textDecoration: 'none' }}>
          contact@xgeeksweb.com
          </a>
        </Typography>
      </Container>
    </Box>
  );
};

export default App;
